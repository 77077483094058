import React from 'react';
import {useSelector} from 'react-redux';

const Footer:React.FC = ()=>{
    let isLogin = useSelector((state:any)=>state.isLogin);

    return (
        <>
        {isLogin?
        <div className="footer">
            <div className="copy-right">&#169; Datapiler - {(new Date()).getFullYear()}</div>
        </div>
        :''}
        </>
        
    );
}

export default Footer;