import { LinearProgress } from '@material-ui/core';
import React,{lazy,Suspense } from 'react';
import {
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";
import { useSelector } from 'react-redux'


const Login = lazy(()=> import('./pages/Login'));
const Machines = lazy(()=> import('./pages/machines/'));
const MachineStats = lazy(()=> import('./pages/machines/stats'));
const Processes = lazy(()=> import('./pages/processes'));
const Queue = lazy(()=> import('./pages/queues'));
const PendingQueue = lazy(()=> import('./pages/queues/pending')); 
const Handlers = lazy(()=> import('./pages/handlers')); 
const Dashboard = lazy(()=> import('./pages/dashboard')); 
const Logs = lazy(()=> import('./pages/logs')); 
const Live = lazy(()=> import('./pages/Live')); 

const CustomRoute = (props:any)=>{
    const isLogin = useSelector((state:any)=>state.isLogin);
    const user = useSelector((state:any)=>state.user);

    if( isLogin && props.role.indexOf(user.role) !== -1 )
        return <Route {...props}></Route>;
    else if(props.role == null)
        return <Route {...props}></Route>;
    else 
        return <Redirect to="/login"/>;
}

const Fallback = ()=>{
    return (
        <>
            <LinearProgress/>    
        </> 
    )
}

const Routers = ()=>{
    return(
        <Suspense fallback={<Fallback/>}>
            <Switch>
                <CustomRoute component={Dashboard} path="/" exact role={['admin']} />
                <CustomRoute component={Machines} path="/machines" exact role={['admin']} />
                <CustomRoute component={MachineStats} path="/machine/stats/:mpid?" exact role={['admin']} />
                <CustomRoute component={Processes} path="/processes/" exact key="processes" role={['admin']} />
                <CustomRoute component={Processes} path="/processes/:mpid" exact key="machine-processes" role={['admin']} />
                <CustomRoute component={Handlers} path="/handlers" exact key="handlers" role={['admin']} />
                <CustomRoute component={Live} path="/live" exact key="handler-test" role={['admin']} />
                <CustomRoute component={Queue} path="/queue/:mpid?/:cpid?" exact key="process-queue" role={['admin']} />
                <CustomRoute component={PendingQueue} path="/pendingQueue" exact key="pending-queue" role={['admin']} />
                <CustomRoute component={Logs} path="/logs" exact key="logs" role={['admin']} />

                <Route component={Login} path="/login" exact key="login" role={null} />

                
            </Switch>
        </Suspense>
    )
}

export default Routers;