import React from 'react';
import {useHistory,withRouter,Link as RLink} from 'react-router-dom';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Icon, IconButton } from '@material-ui/core';
import ComputerIcon from '@material-ui/icons/Computer';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import { DeviceHub, ListAlt, Timeline } from '@material-ui/icons';

import {useSelector,useDispatch} from 'react-redux';
import {logout} from '../store/actions';

export default  function SwipeableTemporaryDrawer() {
    let isLogin = useSelector((state:any)=>state.isLogin);
    let dispatch = useDispatch();
    let history = useHistory();

    const [state, setState] = React.useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState(open);
    };
    
    const handlerLogout = ()=>{
        dispatch(logout());
    }

    const list = () => (
        <div className="left-menu"
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
        <List>
            <ListItem button component={RLink} to="/"  >
                <ListItemIcon><HomeIcon/> </ListItemIcon>
                <ListItemText primary="Home" />
            </ListItem>
        </List>
        <Divider />
        <List>
            <ListItem button component={RLink} to="/machines"  >
                <ListItemIcon><ComputerIcon /></ListItemIcon>
                <ListItemText primary="Machines" />
            </ListItem>
        </List>
        <Divider />
        <List>
            <ListItem button component={RLink} to="/processes"  >
                <ListItemIcon><SettingsInputComponentIcon/></ListItemIcon>
                <ListItemText primary="Processes" />
            </ListItem>
        </List>
        <Divider />
        <List>
            <ListItem button component={RLink} to="/handlers"  >
                <ListItemIcon><DeviceHub/></ListItemIcon>
                <ListItemText primary="Handlers" />
            </ListItem>
        </List>
        <Divider />
        <List>
            <ListItem button component={RLink} to="/queue"  >
                <ListItemIcon><ListAlt/></ListItemIcon>
                <ListItemText primary="Queue" />
            </ListItem>
        </List>
        <List>
            <ListItem button component={RLink} to="/pendingQueue" >
                <ListItemIcon><ListAlt/></ListItemIcon>
                <ListItemText primary="Pending Queue" />
            </ListItem>
        </List>
        <Divider />
        <List>
            <ListItem button component={RLink} to="/logs"  >
                <ListItemIcon><Timeline/></ListItemIcon>
                <ListItemText primary="Logs" />
            </ListItem>
        </List>
        <Divider />
        
        </div>
    );
  
    return (
        <>
            {isLogin?
            <>
                <AppBar position="static" className="appBar">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6">
                            <Icon className="logo">dvrIcon</Icon>
                            <span className="app-name"> Scraper </span>
                        </Typography>
                        <Button onClick={handlerLogout} color="inherit">Logout</Button>
                    </Toolbar>
                </AppBar>        
                
                <SwipeableDrawer
                    anchor="left"
                    open={state}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    {list()}
                </SwipeableDrawer>  
            </>
            :''}
        </>
    );
};