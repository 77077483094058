import React,{useState,useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import SnakAlert from './common/SnakAlert';
import ConfirmationDialog from './common/ConfirmationDialog';
import Header from './common/Header';
import Footer from './common/Footer';
import Routes from './Routes';
import AppInitializer from './AppInitializer';

import {Provider} from 'react-redux';
import store from './store';

import WithClearCache from './WithClearCache';

import './assets/App.css';

function App() {
  return (
    <div className="app">
      <WithClearCache/>
      <Provider store={store}>
        <AppInitializer>
          <Router>
              <Header/>    
              <div className="container">
                  <Routes/>
              </div>
              <Footer/>
          </Router>
        </AppInitializer>
      </Provider>
      <SnakAlert/>
      <ConfirmationDialog/>
    </div>
  );
}


export default App;
