import React,{useState,useEffect} from 'react';
import client from './services/client';

import { useSelector, useDispatch } from 'react-redux'
import {login,logout} from './store/actions';
import { Icon, LinearProgress } from '@material-ui/core';

const AppInitializer = (props:any)=>{
    const dispatch = useDispatch();
    const isLogin = useSelector((state:any)=>state.isLogin);
    const isInitialized = useSelector((state:any)=>state.init);

    const verifyToken = async()=>{
        let auth:any = localStorage.getItem('auth');
        if(auth){
            let user = JSON.parse(auth);
            let res:any = await client.post('/auth/verifyToken',{token:user.token,id:user.id});
            
            if(res.error){
                window.snakAlert.error(res.message);
            }else{
                if(res.success && res.data){
                    // dispath login
                    dispatch(login(res.data));
                }else{
                    dispatch(logout());
                }
            }
        }else{
            dispatch(logout());
        }
    }

    useEffect(()=>{
        if(!isLogin)
            verifyToken();

        if(isLogin){
            document.body.classList.remove('bg-blue');
        }else{
            document.body.classList.add('bg-blue');
        }
    },[isLogin])

    return (
        <>
            {isInitialized?props.children:<SiteLoader/>}
        </>
    )
}

const SiteLoader = ()=>{
    return (
        <div className="site-loader">
            <div className="wrapper round-container">
                <Icon className="logo-icon">dvr</Icon>
                <h1>Monitor Server</h1>    
                <LinearProgress/> 
            </div>
        </div>
    )
}



export default AppInitializer;

